import consumer from "./consumer"

consumer.subscriptions.create("FinishedArticleChannel", {
  connected() {
    console.log("Connected to FinishedArticleChannel");
  },

  disconnected() {
    console.log("Disconnected from FinishedArticleChannel");
  },

  received(data) {
    // let received_url_path = '/articles/' + data.content.id;
    // if (window.location.pathname == received_url_path) {
    //   location.reload();
    // }

    location.reload();
  }
});